@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.CostsInput-input .bp5-input-group {
    width: 70px;
}

.CostsInput-switch {
    margin-bottom: 0;
}

.CostsInput-wrapper {
    display: flex;
    align-items: center;
}

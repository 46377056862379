@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.sensitivities-list-container {
    max-height: 295px;
    overflow-y: auto;
}

.sensitivities-list {
    display: flex;
    justify-content: space-between;
}

.no-sensitivities {
    color: $gray3;
    font-style: italic;
}

.project-sensitivity-metadata {
    display: flex;
    align-items: center;
    gap: $very-small-margin;
}

.sensitivity-size-input {
    .bp5-input-group input.bp5-input {
        width: 50px;
    }

    // The NumericInput rightElement is absolutely positioned but doesn't properly
    // handle the `small` prop, so we fix that here
    .bp5-input-action:last-child {
        top: 3px;
        right: 7px;
    }
    .sensitivity-input-icon {
        pointer-events: none;
        font-weight: 600;
        color: $pt-text-color-muted;
    }
}

.sensitivity-type-select {
    select {
        height: $pt-button-height-small;
        // Matching styles for a Blueprint 5 "small" button.
        // These values aren't exported from Blueprint as variables.
        padding: 0px $button-icon-margin;
        width: 100px;
        font-size: 13px;
    }

    // The icon in the HTMLSelect is absolutely positioned, so we
    // have to update these values to handle our custom "small" styling.
    span.bp5-icon {
        top: 4px;
        right: 4px;
    }
}

.sensitivity-project-size-preview {
    margin-left: $very-small-margin;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.ConnectedLinesSection-wrapper {
    display: flex;
    margin-top: $small-margin;
    gap: $very-small-margin;
}

.ConnectedLinesSection-allow-click {
    cursor: pointer;
}

.ConnectedLinesSection-empty-state {
    margin-top: $very-small-margin;
    margin-bottom: $very-small-margin;
    display: flex;
    justify-content: center;
}

.ConnectedLinesSection-line-wrapper {
    padding-top: $very-small-padding;
    padding-bottom: $very-small-padding;
    display: flex;
}

.ConnectedLinesSection-line-wrapper:not(:last-child) {
    border-bottom: 1px solid rgba(17, 20, 24, 0.15);
}

.ConnectedLinesSection-line-wrapper:last-child {
    margin-bottom: -#{$very-small-margin};
}

.ConnectedLinesSection-line-wrapper:hover {
    background-color: #edeff2;
}

.ConnectedLinesSection-line-icon {
    margin-left: $very-small-margin;
}

.ConnectedLinesSection-line-details {
    display: flex;
    flex-direction: column;
    flex-grow: 4;
    margin-left: $small-margin;
    gap: $very-very-small-margin;
}

.ConnectedLinesSection-line-row1 {
    display: flex;
    gap: 10px;
}

.ConnectedLinesSection-line-tags {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: $very-very-small-margin;
    margin-top: $very-small-margin;
    margin-right: $small-margin;
}

.ConnectedLinesSection-missing-bus-mailto {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $very-very-small-margin;
    margin-right: 20px;
}

.ConnectedLinesSection-project-name-wrapper {
    display: flex;
    flex-direction: row;
    gap: $very-small-margin;
}

.ConnectedLinesSection-project-name {
    max-width: 200px;
}

.ConnectedLinesSection-more-info {
    cursor: help;
}

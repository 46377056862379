@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.SearchResultItem-wrapper {
    background-color: white;
    padding: $small-padding;
    cursor: pointer;
}

.SearchResultItem-wrapper-not-last {
    border-bottom: 1px solid $pt-divider-black;
}

.SearchResultItem-wrapper:hover {
    background-color: $light-gray4;
}

.SearchResultItem-text {
    margin-bottom: $very-small-margin;
}

.SearchResultItem-sub-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.FiltersDialogSection-wrapper {
    border-top: 1px solid $pt-divider-black;
    padding-top: $small-padding;
    padding-bottom: $small-padding;
}

.FiltersDialogSection-wrapper:first-child {
    border-top: none;
    padding-top: 0px;
}

.FiltersDialogSection-heading {
    margin-bottom: $small-margin;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.SearchBar-wrapper {
    width: 300px;
}

.SearchBar-input .bp5-input {
    box-shadow: $pt-elevation-shadow-3;
}

.SearchBar-results-wrapper {
    margin-top: $small-margin;
    box-shadow: $pt-elevation-shadow-3;
}

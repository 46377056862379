@import "/src/sharedStyles/styles.scss";

.ScreeningViewSelectorTab-select {
    width: 350px;
}

.ScreeningViewSelectorTab-metadata {
    max-height: 150px;
    overflow: auto;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.ColorThresholds-wrapper {
    display: flex;
    align-items: center;
}

.ColorThresholds-multi-slider-wrapper {
    width: 65%;
    padding-right: $large-padding;
}

.ColorThresholds-legend-wrapper {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px $pt-divider-black solid;
    flex-grow: 1;
}

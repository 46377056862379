@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.ConstraintsDetailsSection-row {
    display: flex;
    margin-bottom: $very-small-margin;
}

.ConstraintsDetailsSection-row-label {
    width: 45%;
}

.ConstraintsDetailsSection-row-value {
    width: 55%;
    display: flex;
}

.ConstraintsDetailsSection-row-value-text {
    margin-right: $very-small-margin;
}

.ConstraintsDetailsSection-view-upgrades-button {
    padding-top: 1px;
    color: $pt-link-color;
    cursor: pointer;
}

.ConstraintsDetailsSection-view-upgrades-button:hover {
    text-decoration: underline;
}

.ConstraintsDetailsSection-original-value-crossed {
    text-decoration: line-through;
}

.ConstraintsDetailsSection-view-details-button {
    color: $pt-link-color;
    cursor: pointer;
}

.ConstraintsDetailsSection-view-details-button:hover {
    text-decoration: underline;
}

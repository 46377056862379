@import "/src/sharedStyles/styles.scss";

.title-wrapper {
    display: flex;
    align-items: center;
    gap: $small-margin;
}

.buttons {
    display: flex;
    gap: $very-small-margin;
}

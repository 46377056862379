@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.ConstraintDetailsDialog-details-dialog.bp5-dialog {
    width: 85%;
    padding-bottom: 0px;
    max-width: 1200px;
}

.ConstraintDetailsDialog-details-dialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        margin-right: $small-margin;
    }
}

.ConstraintDetailsDialog-body {
    padding: 0;
    overflow: auto;
    height: 80vh;
    max-height: 80vh;
}

.ConstraintDetailsDialog-details-dialog.bp5-dialog .bp5-dialog-header {
    z-index: 10;
}

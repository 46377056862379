@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.LayerPreview-wrapper {
    margin-right: $large-margin;
}

.LayerPreview-wrapper:last-child {
    margin-right: 0;
}

.LayerPreview-img {
    width: 72px;
    height: 72px;
    border-radius: 4px;
    margin-bottom: $very-small-margin;
    border: 1px $pt-divider-black solid;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

.LayerPreview-img-focused {
    border: 2px $pt-icon-color-selected solid;
}

.LayerPreview-text {
    text-align: center;
    cursor: pointer;
}

.LayerPreview-text-focused {
    color: $pt-icon-color-selected;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.CustomInjectionDialog-body {
    display: flex;
}

.CustomInjectionDialog-input-wrapper {
    flex: 1;
    padding-left: $small-padding;
    padding-right: $small-padding;
    border-left: 1px solid $pt-divider-black;
    max-width: 50%;
}

.CustomInjectionDialog-input-wrapper:first-child {
    padding-left: 0px;
    border-left: none;
}

.CustomInjectionDialog-input-wrapper:last-child {
    padding-right: 0px;
}

.CustomInjectionDialog-numeric-input {
    margin-bottom: $small-margin;
}

.CustomInjectionDialog-numeric-input .bp5-input-group {
    width: 75px;
}

.CustomInjectionDialog-slider.bp5-slider {
    width: 100%;
}

.CustomInjectionDialog-dialog {
    width: auto;
    min-width: 500px;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.Legend-section-wrapper {
    padding: $small-padding;
}

.Legend-header-wrapper {
    margin-bottom: $small-margin;
}

.Legend-h6 {
    margin-bottom: 0px;
}

.Legend-storage-info-text {
    margin-top: $small-margin;
    max-width: 170px;
}

.Legend-legend-row {
    display: flex;
    margin-bottom: $very-small-margin;
    align-items: center;
}

.Legend-legend-row:last-child {
    margin-bottom: 0px;
}

.Legend-legend-row-text {
    margin-left: $very-small-margin;
}

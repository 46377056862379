@import "~@blueprintjs/core/lib/scss/variables";

// TODO figure out why blueprint colors aren't working
.ag-theme-balham {
    --ag-foreground-color: #111418;

    --ag-balham-active-color: #2d72d2;

    --ag-header-foreground-color: #111418;
    --ag-header-background-color: #f6f7f9;
    --ag-header-column-separator-height: 100%;
    --ag-header-column-resize-handle-width: 10px;

    --ag-row-hover-color: #edeff2;
    --ag-border-color: #c5cbd3;

    --ag-font-size: 13px;
}

.ag-header-cell-filtered {
    background-color: #d3d8de !important;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.Sidebar-filter-wrapper {
    padding: $small-padding;
    box-shadow: 0px 1px 1px $pt-divider-black;
    z-index: 10;
}

.Sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $pt-app-background-color;
}

.Sidebar-logo-wrapper {
    padding: $small-padding;
    border-bottom: 1px solid $pt-divider-black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Sidebar-filter-wrapper-header {
    margin-bottom: $small-margin;
    display: flex;
    justify-content: space-between;
}

.Sidebar-show-all-filters {
    color: $pt-link-color;
    cursor: pointer;
}

.Sidebar-show-all-filters:hover {
    text-decoration: underline;
}

.Sidebar-filter-wrapper h6 {
    margin-bottom: 0px;
}

@import "~@blueprintjs/core/lib/scss/variables";

.table-ribbon-wrapper {
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.link {
    font-weight: 600;
}

.muted-cell {
    font-style: italic;
    color: $gray3;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.BusItemList-zero-state-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.BusItemList-zero-state-header.bp5-heading {
    color: $pt-text-color-disabled;
    margin-bottom: $small-margin;
}

.BusItemList-zero-state-icon {
    color: $pt-text-color-disabled;
    margin-bottom: $small-margin;
}

.BusItemList-wrapper {
    flex-grow: 1;
}

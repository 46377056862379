@import "/src/sharedStyles/styles.scss";

.results {
    display: flex;
    flex-flow: column;
    gap: $small-margin;
}

.tables {
    display: flex;
    flex-flow: column;
    padding-bottom: $large-padding;
}

.search-input {
    width: 300px;
}

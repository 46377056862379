@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.ConstraintDetailsDialogTable-table {
    width: 100%;
}

.ConstraintDetailsDialogTable-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $pt-app-background-color;
    box-shadow: 0px 1px $pt-divider-black;
}

.ConstraintDetailsDialogTable-further-constraints-td {
    padding: 0px !important;
    border-top: 1px solid $pt-divider-black;
}

.ConstraintDetailsDialogTable-further-constraints-td-open {
    border-bottom: 1px solid $pt-divider-black;
}

.ConstraintDetailsDialogTable-further-constraints-td:hover {
    text-decoration: underline;
    color: $pt-link-color;
}

.ConstraintDetailsDialogTable-further-constraints-row {
    display: flex;
    background-color: $pt-app-background-color;
    padding-left: $small-padding;
    padding-top: $small-padding;
    padding-bottom: $small-padding;
    align-items: center;
}

.ConstraintDetailsDialogTable-further-constraints-row-carat {
    color: $pt-link-color;
}

.ConstraintDetailsDialogTable-further-constraints-row h6 {
    margin-bottom: 0px;
    color: $pt-link-color;
}

.ConstraintDetailsDialogTable-no-constraints-required-row {
    padding-bottom: $small-padding;
    padding-top: $small-padding;
}

.ConstraintDetailsDialogTable-ellipsize {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.h1 {
    color: $dark-gray5;
    font-size: 32px;
    font-weight: 600;
    margin: 0.5em 0;
}

.h2 {
    color: $dark-gray5;
    font-weight: 500;
    margin: 0.5em 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

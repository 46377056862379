@import "/src/sharedStyles/styles.scss";

.scenario-assumptions {
    max-width: 550px;
    + .scenario-assumptions {
        margin-top: $small-margin;
    }
}

.scenario-assumptions-body {
    max-height: 220px;
    overflow-y: scroll;
}

.scenario-assumptions-card {
    padding: 10px 15px;
}

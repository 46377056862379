@import "/src/sharedStyles/styles.scss";

.scenarios-section-header {
    display: flex;
    gap: $small-margin;
}

.show-archived-checkbox {
    margin: auto;
}

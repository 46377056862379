@import "/src/sharedStyles/styles.scss";

.ScreeningViewMetadataSection-description {
    margin-bottom: $small-margin;
}

.ScreeningViewMetadataSection-items {
    display: flex;
    flex-direction: column;
}

.ScreeningViewMetadataSection-item {
    display: flex;
    align-items: flex-start;
}

.ScreeningViewMetadataSection-label {
    flex-basis: 100px;
    flex-shrink: 0;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.CurtailmentConstraintDetailsDialogTable-checkbox-row {
    display: flex;
}

.CurtailmentConstraintDetailsDialogTable-checkbox-row-header {
    width: 100px;
}

.CurtailmentConstraintDetailsDialogTable-checkbox-section {
    margin-top: $small-margin;
    padding-left: $small-margin;
    padding-bottom: $very-small-margin;
    border-bottom: 1px solid $pt-divider-black;
}

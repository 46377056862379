@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.OtherBusesToggle-wrapper {
    align-self: flex-start;
    padding: $very-small-padding;
    background-color: white;
    border-right: 1px solid $pt-divider-black;
    border-left: 1px solid $pt-divider-black;
    border-bottom: 1px solid $pt-divider-black;
    display: flex;
    align-items: center;
}

.OtherBusesToggle-text {
    padding-left: $very-small-padding;
    padding-right: $very-small-padding;
}

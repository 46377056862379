@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.DetailPaneHeader-wrapper {
    margin-bottom: $small-margin;
    align-items: center;
    display: grid;
    grid-template-columns: [col1-start] min-content [col2-start] auto [col3-start] min-content [col4-start] 25px [end];
    grid-template-rows: [row1-start] minmax(24px, auto) [row2-start] auto [end];
}

.DetailPaneHeader-wrapper h5 {
    margin-left: $small-margin;
    margin-bottom: 0px;
}

.DetailPaneHeader-cross {
    justify-self: end;
    align-self: start;
    cursor: pointer;
    grid-column: col4-start / end;
    grid-row: row1-start / row2-start;
}

.DetailPaneHeader-capacity-energy-tags-wrapper {
    display: flex;
    margin-top: $very-small-margin;
    grid-column: col2-start / col3-start;
    grid-row: row2-start / end;
}

.DetailPaneHeader-energy-tag-wrapper {
    margin-left: $small-margin;
}

.DetailPaneHeader-charging-capacity-tag-wrapper {
    margin-left: $very-small-margin;
}

.body {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    min-height: 400px;
}

.cards {
    display: flex;
    flex-flow: row;
    gap: 10px;
}

.card {
    flex: 1;
    :global(.bp5-control-card-label) {
        display: flex;
        flex-flow: column;
        gap: 5px;
    }
}

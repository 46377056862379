@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.UnauthorizedDetailPane-upgrade-section-wrapper {
    display: flex;
    flex-direction: column;
    padding: $large-margin;
    background-color: $pt-app-background-color;
    align-items: center;
    border: 1px solid $pt-divider-black;
    border-radius: 4px;
}

.UnauthorizedDetailPane-upgrade-section-heading.bp5-heading {
    margin-bottom: $small-margin;
}

.UnauthorizedDetailPane-contact-sales-link {
    color: white;
}

.UnauthorizedDetailPane-contact-sales-link:hover {
    color: white;
    text-decoration: underline;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.app-wrapper {
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
    background-color: $light-gray5;
}

.app-layout {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    gap: $small-margin;
    padding: $small-padding $large-padding;
    max-width: 1700px;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    background-color: $white;
    border-bottom: $light-gray1 solid 1px;
    padding: 10px $small-padding;
}

.toolbar-left {
    display: flex;
    gap: $small-margin;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.row {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    max-width: 800px;
}

.card {
    display: flex;
    flex-flow: column;
    flex: 1 1 0;

    padding: $small-margin 20px;
    gap: $very-small-margin;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $light-gray1;
}

.label {
    color: $pt-text-color-muted;
    font-size: $pt-font-size;
}

.value {
    color: $dark-gray5;
    font-size: 32px;
    font-weight: 700;
}

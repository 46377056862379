@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.BusDetailPane-wrapper {
    display: flex;
    flex-direction: column;
}

.BusDetailPane-main-content-wrapper {
    background-color: white;
    padding: $small-padding;
    border: 1px solid $pt-divider-black;
}

.BusDetailPane-info {
    cursor: pointer;
    color: $pt-text-color-muted !important;
    grid-column: col3-start / col4-start;
    grid-row: row1-start / row2-start;
}

.BusDetailPane-row {
    display: flex;
    margin-bottom: $very-small-margin;
}

.BusDetailPane-row:last-child {
    margin-bottom: 0px;
}

.BusDetailPane-row-label {
    width: 45%;
}

.BusDetailPane-row-value {
    width: 55%;
    display: flex;
}

.BusDetailPane-row-value-text {
    margin-right: $very-small-margin;
}

.BusDetailPane-info-popover-content {
    color: $pt-dark-text-color;
}

.BusDetailPane-info-popover-content:hover {
    text-decoration: underline;
}

.BusDetailPane-wrapper h6 {
    margin-bottom: $very-small-margin;
}

.BusDetailPane-details-wrapper {
    margin-bottom: $small-margin;
}

.BusDetailPane-priced-upgrade-default-crossed {
    text-decoration: line-through;
}

.BusDetailPane-unstudied-upgrade-default-crossed {
    text-decoration: line-through;
}

.BusDetailPane-low-confidence-location-callout {
    margin-top: $small-margin;
}

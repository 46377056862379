@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.LayerStyleSelector-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.LayerStyleSelector-button-wrapper {
    background-color: $pt-app-background-color;
    display: inline-flex;
    padding: $small-padding;
    border-radius: 4px;
    border: 1px $pt-divider-black solid;
    color: $pt-text-color-muted;
    cursor: pointer;
}

.LayerStyleSelector-icon {
    align-self: center;
}

.LayerStyleSelector-hover-selector {
    background-color: white;
    margin-bottom: $small-margin;
    border-radius: 4px;
    border: 1px $pt-divider-black solid;
    background-color: $pt-app-background-color;
}

.LayerStyleSelector-layers-section-wrapper {
    border-top: 1px $pt-divider-black solid;
    padding: $small-padding;
}

.LayerStyleSelector-layers-wrapper {
    display: flex;
}

@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.LineTapPane-wrapper {
    display: flex;
    flex-direction: column;
}

.LineTapPane-wrapper h6 {
    margin-bottom: $very-small-margin;
}

.LineTapPane-main-content-wrapper {
    background-color: white;
    padding: $small-padding;
    border: 1px solid $pt-divider-black;
}

.LineTapPane-icon {
    align-self: start;
}

.LineTapPane-row {
    display: flex;
    margin-bottom: $very-small-margin;
}

.LineTapPane-row:last-child {
    margin-bottom: 0px;
}

.LineTapPane-row-label {
    width: 45%;
}

.LineTapPane-row-value {
    width: 55%;
    display: flex;
    align-items: center;
}

.LineTapPane-line-details {
    margin-top: $small-margin;
}

.LineTapPane-bus-row-value {
    display: flex;
    gap: $very-small-margin;
    cursor: pointer;
    padding: 1px;
}

.LineTapPane-bus-capacity-dot {
    width: 16px;
    height: 16px;
}

.LineTapPane-bus-row-value:hover {
    background-color: #edeff2;
}

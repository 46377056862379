.BusCapacityDot-icon {
    border-width: 6px;
    border-style: solid;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: white;
}

.BusCapacityDot-small {
    border-width: 4px;
    width: 16px;
    height: 16px;
}

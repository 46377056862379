@import "/src/sharedStyles/styles.scss";

.RegionSelectorDialog-wrapper .bp5-dialog-header > .bp5-icon {
    margin-right: $small-margin;
}

.RegionSelectorDialog-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.RegionSelectorDialog-body {
    min-height: 250px;
}

.RegionSelectorDialog-learn-more-wrapper {
    width: 400px;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.SidebarResultsWrapper-wrapper {
    flex-grow: 1;
    overflow: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.SidebarResultsWrapper-heading {
    padding: $small-padding 0px $small-padding $small-padding !important;
    margin: 0px !important;
    border-bottom: 1px solid $pt-divider-black;
    background-color: $pt-app-background-color;
}

.SidebarResultsWrapper-view-unauthorized-button {
    color: $pt-link-color;
    cursor: pointer;
    padding: $small-padding 0px $small-padding $small-padding;
}

.SidebarResultsWrapper-view-unauthorized-button:hover {
    text-decoration: underline;
}

.SidebarResultsWrapper-view-unauthorized-button-opened {
    border-bottom: 1px solid $pt-divider-black;
}

.SidebarResultsWrapper-view-unauthorized-button-top-border {
    border-top: 1px solid $pt-divider-black;
}

@import "~@blueprintjs/core/lib/scss/variables";
@import "/src/sharedStyles/styles.scss";

.MapView {
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: [column1-start] 25% [column2-start] 75% [column2-end];
    grid-template-rows: 100%;
}

.MapView-sidebar-wrapper {
    grid-column: column1-start / column2-start;
    grid-row: 1 / span 1;
    box-shadow: 1px 0px 1px $pt-divider-black;
    z-index: $pt-z-index-content;
}

.MapView-map-wrapper {
    grid-column: column2-start / column2-end;
    grid-row: 1 / span 1;
    position: relative;
}

.MapView-detail-pane-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: $large-margin;
    width: 450px;
    overflow-y: auto;
    max-height: calc(100vh - 2 * #{$large-margin});
}

.MapView-layer-selector-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: $button-icon-margin;
    z-index: $pt-z-index-content;
}

.MapView-search-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}

@import "/src/sharedStyles/styles.scss";

.FilterWrapper-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: $small-margin;
}

.FilterWrapper-wrapper:last-child {
    margin-bottom: 0px;
}

.FilterWrapper-label {
    flex: 1;
    padding-right: $small-padding;
}

.FilterWrapper-label-wide {
    flex: 2;
}

.FilterWrapper-label-max-width {
    max-width: 180px;
}

.FilterWrapper-label-align-top {
    align-self: flex-start;
}

.FilterWrapper-input {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    min-width: 0px;
}

.FilterWrapper-input-max-width {
    max-width: 250px;
}

.FilterWrapper-icon {
    margin-left: $very-small-margin;
    cursor: pointer;
}

.FilterWrapper-popover-content-wrapper {
    width: 400px;
}

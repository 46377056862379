@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.BusItem-wrapper {
    padding: $small-padding;
    background-color: white;
    border-bottom: 1px solid $pt-divider-black;
    display: grid;
    grid-template-columns: [col1-start] auto [col2-start] auto [end];
    grid-template-rows: [row1-start] auto [row2-start] auto [end];
    align-items: center;
    cursor: pointer;
}

.BusItem-wrapper-selected {
    background-color: $light-gray4;
}

.BusItem-display-name {
    grid-column-start: col1-start;
    grid-column-end: col2-start;
    grid-row-start: row1-start;
    grid-row-end: row2-start;
}

.BusItem-display-name.bp5-skeleton {
    width: 200px;
    height: 20px;
}

.BusItem-owner-name {
    grid-column-start: col1-start;
    grid-column-end: col2-start;
    grid-row-start: row2-start;
    grid-row-end: end;
}

.BusItem-owner-name.bp5-skeleton {
    width: 150px;
    height: 20px;
}

.BusItem-wrapper > .BusItem-tag:not(:last-child) {
    margin-bottom: $very-small-margin;
}

.BusItem-tag.bp5-skeleton {
    width: 75px;
}

.BusItem-tag {
    justify-self: flex-end;
}

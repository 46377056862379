@import "/src/sharedStyles/styles.scss";
@import "~@blueprintjs/core/lib/scss/variables";

.MapComponent-wrapper {
    height: 100%;
    position: relative;
}

.MapComponent-searchbar-wrapper {
    margin-left: $large-margin;
    margin-top: $large-margin;
    position: absolute;
    top: 0;
}
